import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';

function Page({ data }) {
  const page = data.page;

  return (
    <>
      {page.frontmatter.template === 'sub-page' ? (
        <Layout>
          <div className="hero has-text-centered py-6">
            <h1 className="title is-1 m-2">{page.frontmatter.title}</h1>
          </div>
          <div id={page.frontmatter.id} className="content mt-6 mb-2 centering">
            <MDXRenderer>{page.body}</MDXRenderer>
          </div>
        </Layout>
      ) : page.frontmatter.template === 'city-event' ? (
        <Layout>
          <div id={page.frontmatter.id}>
            <div class="hero-img"></div>
          </div>
          <div className="content mb-2 centering">
            <h1 className="city-title is-1 m-2">{page.frontmatter.city}</h1>
            <h1 className="state-title is-1 m-2">{page.frontmatter.state}</h1>
            <MDXRenderer>{page.body}</MDXRenderer>
          </div>
        </Layout>
      ) : (
        <Layout>
          <div id={page.frontmatter.id}>
            <div class="hero-img"></div>
            <MDXRenderer>{page.body}</MDXRenderer>
          </div>
        </Layout>
        )}
    </>
  );
}

export default Page;

export const pageQuery = graphql`
  query ($id: String) {
    page: mdx(id: { eq: $id }) {
      frontmatter {
        title
        city
        state
        slug
        excerpt
        template
        id
      }
      file: parent {
        ... on File {
          modifiedTime
        }
      }
      body
    }
  }
`;
